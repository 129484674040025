'use strict';

//import 'jquery';
//import 'jquery-ui';
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/widgets/sortable.js';
import 'font-awesome/css/font-awesome.css';

import 'angular';
import 'angular-animate';
import 'angular-cookies';
import 'angular-resource';
import 'angular-sanitize';
import 'angular-touch';
import 'angular-i18n/angular-locale_ja-jp.js';
import 'angular-file-upload';
//import 'moment';
// import 'angular-fullcalendar';

//import 'angular-ui-bootstrap';
import '@uirouter/angularjs';
import '@uirouter/angularjs/lib-esm/legacy/stateEvents.js';
import 'angular-socket-io';
import 'ngstorage';
import 'lodash';

import 'angular-translate/dist/angular-translate.js';
import 'angular-translate/dist/angular-translate-storage-cookie/angular-translate-storage-cookie.js';
import 'angular-translate/dist/angular-translate-storage-local/angular-translate-storage-local.js';
import 'angular-translate/dist/angular-translate-loader-static-files/angular-translate-loader-static-files.js';
import 'angular-translate/dist/angular-translate-handler-log/angular-translate-handler-log.js';

import './vendor/angular-bootstrap/ui-bootstrap-tpls.js';
import './vendor/angular-multi-select/isteven-multi-select.css';
import './vendor/angular-multi-select/isteven-multi-select.js';
import './vendor/angular-smart-table/dist/smart-table.js';
import './vendor/angular-ui-sortable/sortable.js';
// import './vendor/@flowjs/ng-flow/dist/ng-flow-standalone.js';
// import 'fabric/dist/fabric.js';
import 'exif-js/exif.js';
import 'iframe-resizer/js/iframeResizer.min.js';
import 'iframe-resizer/js/iframeResizer.contentWindow.min.js';

import 'angular-bootstrap-calendar/dist/css/angular-bootstrap-calendar.css';
import 'angular-bootstrap-calendar/dist/js/angular-bootstrap-calendar.js';
import 'aws-sdk/dist/aws-sdk.min.js';
import 'ng-file-upload/dist/ng-file-upload.js';
import 'ng-file-upload/dist/ng-file-upload-shim.js';
